import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import ReviewCarousel from './reviewCarousel';

const StyledBackgroundSection = styled(BackgroundImage)`
  width: 100%;
  margin-top: -16rem;
  z-index: 1;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  aspect-ratio: 1725 / 823;
`;

const ReviewSection = styled.section`
  background: var(--light-green);
  padding-top: 11rem;
  @media (max-width: 768px) {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  h2 {
    color: var(--green);
    font-family: 'Abril Fatface';
    text-transform: uppercase;
    font-size: 3.125rem;
    margin-bottom: 5rem;
    z-index: 1000;
  }
  .carousel .control-dots .dot {
    background: #e3e4aa !important;
    box-shadow: none;
  }
  .carousel .control-dots .dot.selected {
    background: var(--green) !important;
  }
  .carousel .control-dots {
    @media (min-width: 769px) {
      display: none;
    }
  }
  .carousel.carousel-slider .control-arrow {
    opacity: 0.2;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .carousel.carousel-slider .control-arrow:hover {
    background: none;
    opacity: 1;
  }
  .carousel.carousel-slider .control-arrow:before {
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
  }
  .carousel .control-next.control-arrow:before {
    border-left: 16px solid var(--green);
  }
  .carousel .control-prev.control-arrow:before {
    border-right: 16px solid var(--green);
  }
`;

const ReviewContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
`;

const Reviews = () => {
  const data = useStaticQuery(
    graphql`
      query {
        qp: file(relativePath: { eq: "reviews-bg.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const qp = data.qp.childImageSharp.fluid;
  return (
    <ReviewSection id="reviews">
      <div className="container">
        <h2 className="center">O Que Dizem de Nós</h2>
        <ReviewContainer>
          <ReviewCarousel />
        </ReviewContainer>
      </div>
      <div className="hide-tablet">
        <StyledBackgroundSection
          Tag="div"
          fluid={qp}
          backgroundColor={`#f1f2c9`}
        ></StyledBackgroundSection>
      </div>
    </ReviewSection>
  );
};

export default Reviews;
