import React from 'react';
import styled from 'styled-components';

import instagram from '../images/icons/instagram-icon-white.svg';
import facebook from '../images/icons/facebook-icon-white.svg';

const FollowUsSection = styled.section`
  background: var(--brown);
  padding: 11rem 0 7rem;
  @media (max-width: 767px) {
    padding: 7rem 0;
  }
  h2 {
    color: var(--white);
    font-family: 'Abril Fatface';
    text-transform: uppercase;
    font-size: 3.125rem;
    margin-bottom: 4rem;
  }
  a {
    display: flex;
    color: var(--white);
    text-decoration: none;
    font-weight: 900;
    font-size: 1rem;
    line-height: 150%;
  }
  img {
    display: inline-block;
    height: 1.3rem;
    margin-right: 1rem;
  }
`;

const followUs = () => {
  return (
    <FollowUsSection>
      <div className="container center">
        <h2>Siga-nos nas redes sociais</h2>
        <div className="grid">
          <div className="col-md-2"></div>
          <div
            className="col-md-4"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <a
              href="https://www.instagram.com/quintadospastores/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagram} alt="Instagram icon" />
              @QUINTADOSPASTORES
            </a>
          </div>
          <div
            className="col-md-4"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <a
              href="https://www.facebook.com/QuintaDosPastores/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebook} alt="Facebook icon" />
              @QUINTADOSPASTORES
            </a>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </FollowUsSection>
  );
};

export default followUs;
