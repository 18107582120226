import React, { useEffect, useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Seo from '../components/seo';
import MenuContext from '../context/MenuContext';
import CallBtn from '../components/callBtn';
import Hero from '../components/hero';
import Benefits from '../components/benefits';
import HousePicker from '../components/housePicker';
import VideoSection from '../components/videoSection';
import Reviews from '../components/reviews';
import FollowUs from '../components/followUs';
import InstagramFeed from '../components/instagramFeed';
import ContactForm from '../components/contactForm';
import Layout from '../components/layout';

const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        qp: file(relativePath: { eq: "contact-bg-wide.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const qp = data.qp.childImageSharp.fluid;
  const menu = useContext(MenuContext);

  useEffect(() => {
    if (menu.show) {
      menu.toggleShow();
    }
  }, []);
  return (
    <Layout>
      <Seo
        title="Um Paraíso no Ribatejo!"
        description="Um paraíso no Ribatejo! Escolha uma de duas quintas privadas para umas férias em família repletas de diversão, junto da natureza e dos animais."
      />
      <CallBtn />
      <Hero />
      <Benefits />
      <HousePicker />
      <VideoSection />
      <Reviews />
      <FollowUs />
      <InstagramFeed />
      <ContactForm background={qp} />
    </Layout>
  );
};

export default IndexPage;
