import React from 'react';
import styled from 'styled-components';

import pool from '../images/icons/pool-icon.svg';
import people from '../images/icons/people-icon.svg';
import bike from '../images/icons/bike-icon.svg';
import animal from '../images/icons/animal-icon.svg';

const BenefitsLine = styled.div`
  background: var(--light-brown);
  text-transform: uppercase;
  color: #f1f2c9;
  font-size: 1.125rem;
  font-weight: 700;
  padding: 4rem 0;
  img {
    margin-bottom: 0.75rem;
    height: 40px;
  }
  @media (max-width: 767px) {
    .group {
      margin-bottom: 1.5rem;
    }
  }
`;

const Benefits = () => {
  return (
    <BenefitsLine>
      <div className="container grid">
        <div className="col-md-3 center">
          <div className="group">
            <img src={pool} alt="Piscina" />
            <div>Piscina</div>
          </div>
        </div>
        <div className="col-md-3 center">
          <div className="group">
            <img src={people} alt="Até 12 pessoas" />
            <div>Até 12 pessoas</div>
          </div>
        </div>
        <div className="col-md-3 center">
          <div className="group">
            <img src={bike} alt="Atividades" />
            <div>Atividades</div>
          </div>
        </div>
        <div className="col-md-3 center">
          <div className="group">
            <img src={animal} alt="Animais" />
            <div>Animais</div>
          </div>
        </div>
      </div>
    </BenefitsLine>
  );
};

export default Benefits;
