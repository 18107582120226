import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Menu from '../components/menu.js';

const StyledBackgroundSection = styled(BackgroundImage)`
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: clamp(800px, 80vh, 950px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 767px) {
    justify-content: flex-start;
  }
`;

const InnerText = styled.div`
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .hero-title {
    font-family: 'Abril Fatface';
    text-transform: uppercase;
    font-size: 6.4rem;
    margin-bottom: 2rem;
    @media (max-width: 767px) {
      margin-top: 10vh;
      font-size: clamp(24px, 10vh, 48px);
    }
  }
  p {
    font-style: italic;
    font-weight: 500;
    font-size: 1.5rem;
    margin-bottom: 4rem;
    @media (max-width: 767px) {
      font-size: clamp(16px, 6vh, 20px);
    }
  }
  a {
    background: var(--green);
    color: var(--light-green);
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.25rem;
    font-weight: 500;
    transition: 0.3s ease-in-out;
    &:hover {
      background: #434934;
    }
  }
`;

const Hero = () => {
  const data = useStaticQuery(
    graphql`
      query {
        qp: file(relativePath: { eq: "qp-hero.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const qp = data.qp.childImageSharp.fluid;
  return (
    <StyledBackgroundSection
      Tag="div"
      className=""
      fluid={qp}
      backgroundColor={`#040e18`}
    >
      <Menu />
      <InnerText className="container">
        <div className="hero-title">Um Paraíso no Ribatejo</div>
        <div>
          <p>Mais que umas férias, uma imersão na natureza!</p>
        </div>
        <div>
          <AnchorLink
            href="#quintas"
            className="btn"
            style={{ display: 'inline-block' }}
          >
            As nossas quintas
          </AnchorLink>
        </div>
      </InnerText>
      <div></div>
    </StyledBackgroundSection>
  );
};

export default Hero;
