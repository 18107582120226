import React, { Component } from 'react';
import styled from 'styled-components';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ReactPlayer from 'react-player/youtube';

const VideoSection = styled.section`
  background: var(--light-brown);
  padding: 7rem 0;
  h2 {
    color: var(--white);
    font-family: 'Abril Fatface';
    text-transform: uppercase;
    font-size: 3.125rem;
    margin-bottom: 4rem;
  }
  .carousel .control-dots {
    margin: 0;
    bottom: -50px !important;
  }

  .carousel .control-dots .dot {
    width: 15px;
    height: 15px;
    background: var(--beije);
    opacity: 1;
    box-shadow: none;
  }

  .carousel .control-dots .dot.selected {
    width: 15px;
    height: 15px;
    background: var(--brown);
    opacity: 1;
  }

  .carousel.carousel-slider .control-arrow {
    opacity: 0.2;
  }

  .carousel.carousel-slider .control-arrow:hover {
    background: none;
    opacity: 1;
  }

  .carousel.carousel-slider .control-arrow:before {
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
  }

  .carousel .control-next.control-arrow:before {
    border-left: 16px solid var(--beije);
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 16px solid var(--beije);
  }
`;

const Container = styled.div`
  max-width: 760px;
  margin: 0 auto;
  padding-bottom: 50px;
`;

const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const videoArray = [
  'https://youtu.be/fNOuOUx6hmI',
  'https://youtu.be/rUeRW-_geMk',
  'https://youtu.be/EyQ1QJoXVtc',
  'https://youtu.be/5q7O9OdwBGw',
];

class VideoCarousel extends Component {
  render() {
    return (
      <Carousel showThumbs={false} showStatus={false}>
        {videoArray.map((video) => (
          <VideoContainer>
            <ReactPlayer
              className="react-player"
              url={video}
              controls={true}
              width="100%"
              height="100%"
            />
          </VideoContainer>
        ))}
      </Carousel>
    );
  }
}

const videoSection = () => {
  return (
    <VideoSection>
      <div className="container center">
        <h2>Alguns vídeos</h2>
        <Container>
          <VideoCarousel />
        </Container>
      </div>
    </VideoSection>
  );
};

export default videoSection;
