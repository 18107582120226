import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

import proceedIcon from '../images/icons/proceed-icon.svg';
import carrotIcon from '../images/icons/QP1/1.svg';
import houseIcon from '../images/icons/QP1/2.svg';
import fireplaceIcon from '../images/icons/QP1/3.svg';
import trampolinIcon from '../images/icons/QP1/4.svg';
import saunaIcon from '../images/icons/QP1/5.svg';
import swingIcon from '../images/icons/QP1/6.svg';
import treehouseIcon from '../images/icons/QP1/7.svg';
import swimmingIcon from '../images/icons/QP1/8.svg';
import barbecueIcon from '../images/icons/QP1/9.svg';
import bikeIcon from '../images/icons/QP1/10.svg';
import gamesIcon from '../images/icons/QP1/11.svg';
import peopleIcon from '../images/icons/QP1/12.svg';
import animalIcon from '../images/icons/QP1/13.svg';
import bedroomIcon from '../images/icons/QP1/14.svg';
import snookerIcon from '../images/icons/QP2/Snooker.svg';
import pingPongIcon from '../images/icons/QP2/PingPong.svg';
import chickenIcon from '../images/icons/QP2/Chicken.svg';

const Picker = styled.section`
  h2 {
    color: var(--white);
    font-family: 'Abril Fatface';
    text-transform: uppercase;
    font-size: 2.75rem;
    @media (max-width: 767px) {
      font-size: clamp(18px, 10vw, 28px);
      margin-bottom: 3.5rem;
    }
  }
  .icon-list {
    padding: 2rem 4rem;
    color: var(--white);
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 3rem;
    span {
      display: inline-block;
      margin-right: 1rem;
    }
    img {
      height: 20px;
      margin-right: 0.5rem;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
`;

const StyledBackgroundSection = styled(BackgroundImage)`
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Content = styled.div`
  padding: 5.25rem 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const HousePicker = () => {
  const data = useStaticQuery(
    graphql`
      query {
        qp1: file(relativePath: { eq: "qp1.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        qp2: file(relativePath: { eq: "qp2.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const qp1 = data.qp1.childImageSharp.fluid;
  const qp2 = data.qp2.childImageSharp.fluid;
  return (
    <Picker className="grid" style={{ gridGap: 0 }} id="quintas">
      <StyledBackgroundSection
        Tag="div"
        className="col-md-6 center"
        fluid={qp1}
        backgroundColor={`#040e18`}
      >
        <Content>
          <h2>Quinta dos Pastores I</h2>
          <div className="icon-list">
            <span>
              <img src={houseIcon} alt="170m2" /> 170 m
              <sup>
                <small>2</small>
              </sup>
            </span>
            <span>
              <img src={bedroomIcon} alt="Quartos" /> 3 Quartos
            </span>
            <span>
              <img src={peopleIcon} alt="Pessoas" /> Até 10 Pessoas
            </span>
            <span>
              <img src={swimmingIcon} alt="Piscina" /> Piscina de Água Salgada
            </span>
            <span>
              <img src={barbecueIcon} alt="Churrasco" /> Churrasco
            </span>
            <span>
              <img src={bikeIcon} alt="Bicicletas" /> Bicicletas
            </span>
            <span>
              <img src={gamesIcon} alt="Jogos" /> Jogos
            </span>
            <span>
              <img src={snookerIcon} alt="Snooker" /> Snooker
            </span>
            <span>
              <img src={treehouseIcon} alt="Casa na Árvore" /> Casa na Árvore
            </span>
            <span>
              <img src={trampolinIcon} alt="Trampolim" />
              Trampolim
            </span>
            <span>
              <img src={swingIcon} alt="Baloiço" /> Baloiço
            </span>
            <span>
              <img src={carrotIcon} alt="Horta" /> Horta
            </span>
            <span>
              <img src={animalIcon} alt="Animais" /> Animais
            </span>
            <span>
              <img src={saunaIcon} alt="Sauna" /> Sauna
            </span>
            <span>
              <img src={fireplaceIcon} alt="Lareira" /> Ar Condicionado /
              Lareira
            </span>
          </div>
          <div>
            <Link to="/qp1/">
              <img
                src={proceedIcon}
                className="proceed-icon"
                alt="Explorar a Quinta dos Pastores I"
              />
            </Link>
          </div>
        </Content>
      </StyledBackgroundSection>
      <StyledBackgroundSection
        Tag="div"
        className="col-md-6 center"
        fluid={qp2}
        backgroundColor={`#040e18`}
      >
        <Content>
          <h2>Quinta dos Pastores II</h2>
          <div className="icon-list">
            <span>
              <img src={houseIcon} alt="170m2" /> 170 m
              <sup>
                <small>2</small>
              </sup>
            </span>
            <span>
              <img src={bedroomIcon} alt="Quartos" /> 5 Quartos
            </span>
            <span>
              <img src={peopleIcon} alt="Pessoas" /> Até 12 Pessoas
            </span>
            <span>
              <img src={swimmingIcon} alt="Piscina" /> Piscina
            </span>
            <span>
              <img src={barbecueIcon} alt="Churrasco" /> Churrasco
            </span>
            <span>
              <img src={bikeIcon} alt="Bicicletas" /> Bicicletas
            </span>
            <span>
              <img src={gamesIcon} alt="Jogos" /> Jogos
            </span>
            <span>
              <img src={trampolinIcon} alt="Trampolim" />
              Trampolim
            </span>
            <span>
              <img src={pingPongIcon} alt="Ping Pong" /> Ping Pong
            </span>
            <span>
              <img src={chickenIcon} alt="Animais" /> Animais
            </span>
            <span>
              <img src={fireplaceIcon} alt="Lareira" /> Ar Condicionado /
              Lareira
            </span>
          </div>
          <div>
            <Link to="/qp2/">
              <img
                src={proceedIcon}
                className="proceed-icon"
                alt="Explorar a Quinta dos Pastores II"
              />
            </Link>
          </div>
        </Content>
      </StyledBackgroundSection>
    </Picker>
  );
};

export default HousePicker;
